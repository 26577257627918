<template>
    <div class="container card mt-5 p-3 h-100">
        <div class="d-flex flex-column align-items-center my-5">

            <span class="my-4 font-weight-bold fs-4">We regret to inform you that your quotation has been cancelled.
                Please visit our website to regenerate the quotation at your convenience.</span>
            <b-button class="my-4 generate-btn px-4" @click="pushToQuote()">Generate a Quote</b-button>
        </div>
    </div>
</template>


<script>
export default {

    methods: {
        pushToQuote: function () {

            return this.$router.push('/b2b')
        }
    }
}

</script>

<style scoped>
.generate-btn {
    background-color: #016938;
    border-radius: .5rem
}
</style>