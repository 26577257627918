<template>
    <div>
        <Header v-if="!isArabic"/>
        <Header_ar v-if="isArabic"/>
        <SplashScreen v-if="splash" />
        <div class="mt-5 pb-2" v-responsive.lg.xl v-if="order">
            <h3>{{ filteredData(5) }}</h3>
            <div class="d-flex justify-content-center mt-5">
                <b-container class="bv-example-row d-flex flex-wrap flex-md-nowrap">
                    <!-- Device Information Box -->
                    <div class="info-box border border-secondary mb-2 col-12 col-md-2 notranslate"
                        style="min-height: fit-content">
                        <div class="info-box border border-secondary mb-2 mt-2" style="min-height: 55px;">
                            <p class="mt-4" style="color: #259e07; font-weight: 900;">{{ filteredData(21) }}</p>
                            <p>{{ order.model }}</p>
                        </div>
                        <div class="info-box border border-secondary mb-2" style="min-height: 55px;">
                            <p class="mt-4" style="color: #259e07; font-weight: 900;">{{ filteredData(22) }}</p>
                            <p>{{ order.imei }}</p>
                        </div>
                        <div class="info-box border border-secondary mb-2" style="min-height: 55px;">
                            <p class="mt-4" style="color: #259e07; font-weight: 900;">{{ filteredData(23) }}</p>
                            <p>{{ order.unique_id }}</p>
                        </div>
                    </div>
                    <!-- Content Section -->
                    <div class="info-box justify-content-center border border-secondary mb-2 col-12 col-md-10 ml-0 ml-md-3 scroll"
                        style="max-height: 550px; min-height: 550px; overflow-y: auto;">
                        <img src="../assets/Buyback_content/2 - Choose Brands & Product.svg"
                            class="col-md-12 img-fluid" />
                        <h5 class="text-center">{{ info_text }}</h5>
                        <b-row class="d-flex justify-content-center condition" v-if="order">
                            <b-col v-for="i in conditions" :key="i.id"
                                @click="i.validity ? selected_condition_pop(i) : ''" class="col-sm-4">
                                <div class="info-box3 txt border border-secondary ">
                                    <h4 class="mt-3">{{ i.name }}</h4>
                                    <h5>{{ i.amount + ' ' + i.currency }}</h5>
                                    <Pagecontent :content="i.des" />
                                </div>
                            </b-col>
                        </b-row>
                        <hr />
                        <h6 class="text-center"><i>{{ filteredData(24) }}</i></h6>
                    </div>
                </b-container>
            </div>
        </div>


        <div class="mt-5 pb-2" v-responsive.sm.xs v-if="order">
            <h3 class="text-center">{{ filteredData(5) }}</h3>
            <div class="d-flex justify-content-center mt-5">
                <b-container class="bv-example-row d-flex flex-wrap flex-md-nowrap">
                    <!-- Device Information Box -->
                    <div class="info-box border border-secondary mb-2 col-12 col-md-2 notranslate"
                        style="min-height: fit-content">
                        <div class="info-box border border-secondary mb-2 mt-2" style="min-height: 55px;">
                            <p class="mt-4" style="color: #259e07; font-weight: 900;">Device Name</p>
                            <p>{{ order.model }}</p>
                        </div>
                        <div class="info-box border border-secondary mb-2" style="min-height: 55px;">
                            <p class="mt-4" style="color: #259e07; font-weight: 900;">IMEI / SERIAL NO:</p>
                            <p>{{ order.imei }}</p>
                        </div>
                        <div class="info-box border border-secondary mb-2" style="min-height: 55px;">
                            <p class="mt-4" style="color: #259e07; font-weight: 900;">UNIQUE IDENTIFIER:</p>
                            <p>{{ order.unique_id }}</p>
                        </div>
                    </div>
                    <!-- Content Section -->
                    <div class="info-box justify-content-center border border-secondary mb-2">
                        <h6 class="text-center">{{ info_text }}</h6>
                        <h6 class="text-center"><i>{{ filteredData(24) }}</i></h6>
                    </div>
                    <div
                        class="info-box justify-content-center border border-secondary mb-2 col-12 col-md-10 ml-0 ml-md-3">
                        <!-- <img src="../assets/Buyback_content/2 - Choose Brands & Product.svg"
                            class="col-md-12 img-fluid" /> -->

                        <b-row class="d-flex justify-content-center condition" v-if="order">
                            <b-col v-for="i in conditions" :key="i.id"
                                @click="i.validity ? selected_condition_pop(i) : ''"
                                class="col-12 col-sm-3 col-md-4 mb-3 custom-col">
                                <div class="info-box3 txt border border-secondary text-center">
                                    <h4 class="mt-3">{{ i.name }}</h4>
                                    <h5>{{ i.amount + ' ' + i.currency }}</h5>
                                    <Pagecontent :content="i.des" />
                                </div>
                            </b-col>
                        </b-row>
                        <hr />

                    </div>
                </b-container>
            </div>
        </div>


        <!-- <hr /> -->
        <div class="d-lg-block">
            <b-container class="mt-5 text-right pr-0">
                <b-button @click=GBB_History() variant="success" class="btn btn-success button_action">{{ filteredData(33) }}</b-button>

            </b-container>
            <b-container class="d-flex  justify-content-between text-left outer_layer p-5 flex-wrap">
                <div class="mt-3 col-12 col-md-6">
                    <h3 class="text-left ml-3 mt-2" style="color: #3E3D37;"> {{ filteredData(1) }}</h3>
                    <hr />
                    <div>
                        <div class="p-2">
                            <label class="text-left">{{ filteredData(2) }}</label>
                            <b-form-input placeholder="ABC1245667" max="999999999" v-model="imei_no"
                                class="inp"></b-form-input>
                        </div>
                        <div class="mt-3 d-flex justify-content-end">
                            <b-button variant="success" class="btn btn-success button_action"
                                @click="getOrderDetails">{{ filteredData(25) }}</b-button>
                        </div>
                    </div>
                </div>
                <div class="mt-3 col-12 col-md-6 text-center">
                    <h3 class="text-left ml-3 mt-2" style="color: #3E3D37;">{{ filteredData(3) }}</h3>
                    <hr />
                    <p class="text-left ml-3 mt-3" style="color: #3E3D37;">{{ filteredData(4) }}</p>
                    <div class="mt-3 d-flex justify-content-end">
                        <b-button variant="success" class="btn btn-success button_action"
                            @click="buypass_request()">{{ filteredData(26) }}</b-button>
                        <Abbform @updateData="handleDataUpdate" />
                    </div>
                </div>
            </b-container>
        </div>

        <b-modal id="bv-confirm_condition" scrollable hide-footer size="md" title="Claim Form">
            <div class="d-block text-center" v-if="selected_condition">
                <div>
                    <h6>Model: {{ order.model }}</h6>
                    <h6>{{ selected_condition.name }}</h6>
                    <h6>Price: {{ selected_condition.amount + ' ' + selected_condition.currency }}</h6>
                </div>

                <div class="text-left">
                    <h6>Instructions</h6>
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-checkbox v-for="option in options" v-model="selected" :key="option.value"
                            :value="option.value" :aria-describedby="ariaDescribedby">
                            {{ option.text }}
                            <p v-if="option.text === 'Quality and Eligibility'" style="font-size: smaller;">I have
                                checked the device and documented all faults. I certify that the device is not
                                counterfeit or stolen.</p>
                            <p v-if="option.text === 'Sim Card Removal'" style="font-size: smaller;">I have removed the
                                SIM card and reinserted the SIM tray into the device.</p>
                            <p v-if="option.text === 'Data Wiped'" style="font-size: smaller;">I have wiped the device
                                of all personal data.</p>
                            <p v-if="option.text === 'Find My Device & icloud'" style="font-size: smaller;">I have
                                ensured that Find My iPhone and icloud is turned off for the device.</p>
                        </b-form-checkbox>
                    </b-form-group>
                </div>
                <VueUploadMultipleImage @updateData2="handleDataUpdate2" />
                
                <div class="p-2 text-left" v-if="!order.invoice_data">
                    <label style="font-weight: 800; font-size: small;">Upload the original purchase invoice</label> 
                    <image-uploader :preview="false" :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                         capture="environment" :debug="1"  
                        :autoRotate="true" outputFormat="verbose" @input="setImage"></image-uploader>   
                </div>
                <div class="p-2 text-left" v-if="!order.invoice_data">
                    <label style="font-weight: 800; font-size: small;">Upload proof of exchange by an authorized apple
                        service center.</label>
                        <image-uploader :preview="false" :className="['fileinput', { 'fileinput--loaded': hasImage2 }]"
                         capture="environment" :debug="1"  
                        :autoRotate="true" outputFormat="verbose" @input="setImage2"></image-uploader>   
                </div>
                <div>
                    <Add_device_abb @devicedata="new_device" :cate="cate" :brands="brands" :sku_models="sku_models"
                        @category="select_category" @sbrand="select_brand" />
                </div>
            </div>
            <div class="d-flex justify-content-between mt-3">
                <b-button class="m-2" variant="outline-danger" block @click="hideModal()">Cancel</b-button>
                <b-button class="m-2" variant="outline-success" block @click="confirm_claim()">Confirm</b-button>
            </div>
        </b-modal>
        <Footer />

    </div>
</template>
<script>
import Header from '@/components/Header3.vue'
import Header_ar from '@/components/Header3_ar.vue'
import Footer from '@/components/Footer.vue'
import api from '../api'
import ImageUploader from 'vue-image-upload-resize'
import Pagecontent from '../components/Pagecontent.vue'
import Abbform from '../components/request_abb.vue'
import VueUploadMultipleImage from '../components/VueUploadMultipleImage3.vue'
import Add_device_abb from '../components/Add_device_abb.vue'
import SplashScreen from '../components/SplashScreen.vue'
import lang_ar from '@/data/lang_ar.js'

export default {
    name: 'Claim',
    data() {
        return {
            lang_ar: lang_ar,
            splash: false,
            info_text: '',
            invoice_no: '',
            imei_no: '',
            order: null,
            conditions: null,
            selected_condition: null,
            options: [
                { text: 'Quality and Eligibility', value: 'Quality' },
                { text: 'Sim Card Removal', value: 'Sim' },
                { text: 'Data Wiped', value: 'Data' },
                { text: 'Find My Device & icloud', value: 'Find' }
            ],
            selected: [],
            hasImage: false,
            image: null,
            hasImage2: false,
            image2: null,
            receivedData: null,
            images_phones: null,
            new_devices: [],

            cate: [],
            brands: [],
            sku_models: []
        }
    },
    components: {
        SplashScreen,
        Header,
        Footer,
        Pagecontent,
        Abbform,
        ImageUploader,
        VueUploadMultipleImage,
        Add_device_abb,
        Header_ar
    },
    computed: {
        Language: function () {
            return this.$store.state.selectedLanguage
        },
        isArabic() {
            return this.$store.state.selectedLanguage === 'ar';
        }
    },
    methods: {
        filteredData: function (id) {

            // Retrieve the selected language from Vuex
            const lang = this.$store.state.selectedLanguage;

            // Assuming this.lang_ar contains the data array
            const filtered_data = this.lang_ar.find(item => item.id === id);

            // Check if the item is found and has the desired language key
            if (filtered_data) {
                return filtered_data[lang] || `No data available for the selected language: ${lang}`;
            } else {
                return `No data found for the provided ID: ${id}`;
            }
        },
        GBB_History: function () {
            this.$router.push({ name: 'Abb_records' })

        },
        get_categories: function () {
            const inputs = {
                'reuse_sell': true,
            }
            api.request(process.env.VUE_APP_Get_Categories, inputs).then((response) => {
                if (response.data.result && typeof response.data.result === 'object') {
                    this.cate = response.data.result
                } else {
                    throw response.data
                }
            }).catch(() => {
                this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
            })
        },
        get_brands(newData) {
            const inputs = {
                'cate_id': newData

            }
            api
                .request(process.env.VUE_APP_Get_Brand_Data, inputs)
                .then((response) => {
                    var data = response.data
                    if (data.errorcode) {
                        return
                    }
                    if (data.result) {
                        this.brands = data.result
                    }
                })
                .catch((error) => {
                    // console.log('What happened? ' + error)
                })
        },
        get_model(newData) {
            console.log('full', newData)
            // return
            const inputs = {
                'category_id': newData.category,
                'brand_id': newData.brand

            }
            api
                .request(process.env.VUE_APP_ABB_SKU_ALL, inputs)
                .then((response) => {
                    var data = response.data
                    if (data.errorcode) {
                        return
                    }
                    if (data.result) {
                        this.sku_models = data.result
                    }
                })
                .catch((error) => {
                    // console.log('What happened? ' + error)
                })
        },
        setImage: function (file) {
            this.hasImage = true
            this.image = file.dataUrl.replace('data:', '').replace(/^.+,/, '')
        },
        setImage2: function (file) {
            this.hasImage2 = true
            this.image2 = file.dataUrl.replace('data:', '').replace(/^.+,/, '')
        },
        handleDataUpdate(newData) {
            this.receivedData = newData;
            this.getOrderDetails()
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 1000);
        },
        handleDataUpdate2(newData) {
            this.images_phones = newData;
        },
        new_device(newData) {
            this.new_devices = newData;
        },
        select_category(newData) {
            this.get_brands(newData)
        },
        select_brand(newData) {
            console.log('sdsd')
            this.get_model(newData)
        },
        buypass_request() {
            this.$bvModal.show('bv-abb_form')
        },
        confirm_claim() {
            if (this.selected.length < 4) {
                this.$bvToast.toast('We are not able to process this request right now. Incomplete Receiving Checklist.', {
                    title: 'Mark all the check in Checklist',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            if (!this.hasImage && this.order.invoice_data === false) {
                this.$bvToast.toast('We are not able to process this request right now. Please upload the Invoice Image', {
                    title: 'Invoice Image not Found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }

            if (!this.images_phones) {
                this.$bvToast.toast('We are not able to process this request right now. Please upload the Device Images', {
                    title: 'Device Images not Found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            console.log('dsfsdfsd')
            this.upload_invoice()
        },
        hideModal() {
            this.$bvModal.hide('bv-confirm_condition')
        },
        upload_invoice() {
            this.splash = true
            let currentDate = new Date().toJSON().slice(0, 10);

            const inputs = {
                'user_id': this.$store.state.user.uid,
                'user_token': this.$store.state.user.user_token,
                'token_data': this.$store.state.user.token_data,
                'invoice': this.order.customer_invoice,
                'imei': this.order.imei,
                'date': currentDate,
                'condition': this.selected_condition.id,
                'amount': this.selected_condition.amount,
                'currency': this.selected_condition.currency_id,
                'unique_id': this.order.unique_id,
                'attachment1': this.images_phones[0].path.replace('data:', '').replace(/^.+,/, ''),
                'attachment2': this.images_phones[1].path.replace('data:', '').replace(/^.+,/, ''),
                'type1': 'png',
                'file_type1': 'one',
                'type2': 'png',
                'file_type2': 'two',
                'attachment': this.image || '',
                'type': 'png',
                'file_type': 'invoice',
                'attachment3': this.image2 || '',
                'type3': 'png',
                'file_type3': 'invoice_exchange',
                'new_devices': this.new_devices

            }
            api.request(process.env.VUE_APP_POST_CLAM, inputs).then((response) => {
                const data = response.data
                if (data.result) {
                    this.quote_request = data.result

                    this.$router.push({ name: 'ABB_Track', params: { 'id': this.quote_request } })

                }
            }).catch((error) => {
                this.splash = false
                this.$bvToast.toast('We are not able to process this request right now. Try Again', {
                    title: 'data not Found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                this.errormessage = error
            })
        },
        _collapse: function (i) {
            console.log('Checking')
            this.$root.$emit('bv::toggle::collapse', i)
        },
        selected_condition_pop: function (i) {
            // this.$bvModal.show('bv-confirm_condition')
            this.selected_condition = i
            console.log('Hello World')
            this.new_devices = []
            this.$bvModal.show('bv-confirm_condition')
        },

        getOrderDetails: function () {
            this.order = null
            if (this.receivedData) {
                this.imei_no = this.receivedData
            }
            if (this.invoice_no.length < 3 && this.imei_no.length < 5) {
                this.$bvToast.toast('We are not able to process this request right now. Please write the invoice number or device IMEI', {
                    title: 'Invoice / IMEI No not Found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            const inputs = {

                'invoice': this.invoice_no,
                'imei': this.imei_no,
                'user_id': this.$store.state.user.uid

            }
            api.request(process.env.VUE_APP_GET_QUOTE, inputs).then((response) => {
                console.log(response.data)
                if (response.data.result && response.data.result.length > 0) {
                    this.order = response.data.result[0]
                    this.order2 = response.data.result
                    this.getconditiondetails()
                } else if (response.data.result.error) {
                    this.$bvToast.toast(response.data.result.error, {
                        title: 'Already Claimed',
                        variant: 'danger',
                        toaster: 'b-toaster-top-right'
                    })
                }
                else {
                    this.$bvToast.toast('We are not able to process this request right now. Please Enter the correct invoice number or device IMEI', {
                        title: 'Invoice / IMEI No is incorrect',
                        variant: 'danger',
                        toaster: 'b-toaster-top-right'
                    })
                }
            }).catch((error) => {
                this.errormessage = error
            })
        },
        getconditiondetails: function () {
            let currentDate = new Date().toJSON().slice(0, 10);

            const inputs = {
                'invoice': this.invoice_no,
                'imei': this.imei_no,
                'date': currentDate,
                'language': this.$store.state.selectedLanguage


            }
            api.request(process.env.VUE_APP_GET_CONDITIONS, inputs).then((response) => {
                if (response.data.errorcode) {
                    this.errormessage = response.data.errormessage
                } else if (response.data.result && typeof response.data.result === 'object') {
                    this.conditions = response.data.result
                    this.info_text = response.data.result[0].info_msg
                    // console.log(this.order)
                } else if (response.data.result && typeof response.data.result === 'string') {
                    this.errormessage = response.data.result
                }
            }).catch((error) => {
                this.errormessage = error
            })
        },
        handle_okay: function () {
            this.$router.push({ name: 'ABB_Track', params: { 'id': this.track_data } })
        },
        handle_cancel: function () {
            window.location.reload()
        }
    },
    mounted() {
        this.get_categories()
    }
}
</script>

<style scoped>
.payment-method:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    background-color: green !important;
    border-color: green !important;
    color: white !important;
}

.payment-method {
    border-color: rgb(0, 0, 0) !important;
    color: rgb(0, 0, 0) !important;
    border-radius: 0px;
}

.payment-method:hover {
    background-color: transparent !important;
    border-color: green !important;
    color: green !important;
}

.btn-secondary {
    color: rgb(0, 0, 0);
    background-color: transparent;
    border-color: #6c757d;
}

.inp {
    height: 50px;
    border-radius: 0px;

}

.outer_layer {
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.back {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.b-icon.bi {
    height: 45px;
    width: 45px;
    fill: #bbb;
}

.register-wraper {
    max-width: 300px;
    margin: 0 auto;
    padding: 10px 15px;
    text-align: center;
}

.otp-inputnew {
    width: 45px;
    height: 40px;
    padding: 5px;
    margin: 0 10px !important;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-style: dashed;
    text-align: center;
    margin-right: 50px;
}

/* Background colour of an input field with value */
.otp-inputnew.is-complete {
    background-color: #e4e4e4;
}

.otp-inputnew::-webkit-inner-spin-button,
.otp-inputnew::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.otp-title {
    color: #3FAF2B;
    font-weight: bold;
    font-size: 24px !important;
}
</style>

<style scoped>
.img-preview {
    width: 150px !important;
    height: 150px;
}

.fileinput--loaded {
    width: 200px;
}

.icon-left {
    position: absolute;
    right: -20px;
    top: 0;
}

a {
    color: #A9A9A9 !important;
}

/* .selection-box-shadow {
        animation: mymove 3s infinite;
      } */

@keyframes mymove {
    50% {
        box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
        -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
        -moz-box-shadow: 0px 0px 15px 0px rgba(0, 193, 56, 0.75);
    }
}

.aa {
    text-align: left;
    margin-left: 20px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -10px;
    background: #FFFFFF;
    width: fit-content;
    position: absolute;
    display: block;
    z-index: 2;
    border-radius: 5px;
}

.details {
    padding: 20px;
    margin: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 7px;
}

.details2 {
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 7px;
}

.details2 b {
    margin-left: 20px;
}

.list-group-item {
    color: black !important;
}

.list-group-item.active {
    background-color: rgb(56, 173, 56) !important;
    border-color: rgb(56, 173, 56) !important;
}

.explore_btn {
    background-color: rgb(56, 173, 56) !important;
    color: #fff;
    border: none;
}

.info-box {
    margin-right: 3px;
    margin-left: 3px;
    padding: 3px;
    border-radius: 7px;
    border-color: #e0e0ec !important;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 7px;
}

.info-box4 {
    margin-right: 3px;
    margin-left: 3px;
    padding: 3px;
    border-radius: 7px;
    border-color: #e0e0ec !important;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 7px;
}

.info-box4:hover {
    background-color: green;
    color: white;
}

.info-box2 {
    margin-right: 3px;
    margin-left: 3px;
    padding: 3px;
    border-radius: 7px;
    border-color: green !important;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

}

.info-box2:hover {
    background-color: green;
    color: white;
}

.info-box3 {
    border-color: green !important;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.info-box3:hover {
    background-color: green;
    color: white;

    p {
        color: white;
    }
}

.otp-inputnew {
    width: 45px;
    height: 40px;
    padding: 5px;
    margin: 0 10px !important;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-style: dashed;
    text-align: center;
    margin-right: 50px;
}

/* Background colour of an input field with value */
.otp-inputnew.is-complete {
    background-color: #e4e4e4;
}

.otp-inputnew::-webkit-inner-spin-button,
.otp-inputnew::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.otp-title {
    color: #3FAF2B;
    font-weight: bold;
    font-size: 24px !important;
}

.col {
    flex-grow: 0;
}

.scroll {
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
}

.txt {
    font-size: smaller;
}

.aa {
    text-align: left;
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
    background: #FFFFFF;
    width: fit-content;
    position: absolute;
    display: block;
    font-size: smaller;
    font-weight: lighter;
    z-index: 2;
    border-radius: 5px;
}

.btnn:hover {
    background-color: #3FAF2B;
    border-color: #3FAF2B;
}

footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #FFFFFF !important;
    border-radius: 15px 15px 0px 0px;
    justify-content: center;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.5) !important;
}

.aaa {
    color: black !important;
}

.aaa:hover {
    color: #FFFFFF !important;
}

.a {
    color: black !important;
    ;
}

.txt2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

a {
    color: black !important;
}

.amount_input input {
    width: fit-content;
    text-align: center;
}

.amount_input {
    justify-content: center;
    text-align: center;

}

.amount_input form {
    justify-content: center;
    display: flex;

}

.txt p {
    color: #3FAF2B;
    font-weight: 600;
}

button:hover {
    background-color: #3FAF2B;
    border-color: #3FAF2B;
}

.info-box p {
    word-wrap: break-word;
    /* This will wrap long words onto the next line */
    overflow-wrap: break-word;
    /* This is another property to ensure word wrapping */
}

.info-box h6 {
    font-weight: 700;
}

.button_action {
    border-radius: 0%;
    font-size: larger;
}

.custom-col {
    flex-basis: 100%;

}

@media (max-width: 576px) {


    .custom-col {
        flex-basis: 60%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>