<template>
  <div class="container  card mt-5 p-3">
    <div class="row">
      <div class="col-md-12 mt-3 text-center">
        <h4 class="text-success">Build Your Quote</h4>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="card text-center p-5">
          <div class="card-body p-5">
            <div class="">
              <p>
                Thank you for choosing service
              </p>
              <h3 style="color: green;">
                Quotation number: {{ id }}
              </h3>
              <p class="">
                Your buyback order number has been generated.
                Please confirm the quotation sent to your email. Our liquidator will be in touch with you
                soon.
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      id:this.$route.params.id
    }
  },
  components: {

  },
  methods: {

  }
};
</script>

<style scoped>
h4{
  color: rgba(1, 105, 56, 1) !important;
}
h3{
  color: rgba(1, 105, 56, 1) !important;
  font-weight: 900;

}
.card-body{
  height: 100%;
}
</style>