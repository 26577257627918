<template>
  <div class="container card mt-5 p-3">

    <div class="text-center">
      <h4 class="text-success">Build Your Quote</h4>

    </div>
    <QuoteSummary />
    <ActionButtons />
  </div>
</template>

<script>
import QuoteSummary from '../components/b2b/QuoteSummary.vue';
import ActionButtons from '../components/b2b/ActionButtons.vue';

export default {
  components: {
    QuoteSummary,
    ActionButtons,
  },
  mounted() {

    console.log('*************', this.$store.state.fileImport);

  }
};
</script>

<style scoped>
h4 {
  color: rgba(1, 105, 56, 1) !important;
}
</style>