<template>
    <div class="container card mt-5 p-3">
        <div class="quote-summary">
            <h5 class="text-center mb-3 font-weight-bold text-success">Quotation Summary</h5>
            <div v-if="dataLoading" class="d-flex justify-content-center my-4">
                <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <table v-else>
                <thead>
                    <tr>
                        <th>Device</th>
                        <th>Condition</th>
                        <th>Quantity</th>
                        <th>Estimation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="i in quoteData.devices" :key="i.id">
                        <td>
                            {{ i.name }}
                        </td>
                        <td v-if="i.grade">
                            <b v-if="i.grade === 'a'">Condition 1</b>
                            <b v-if="i.grade === 'b'">Condition 2</b>
                            <b v-if="i.grade === 'c'">Condition 3</b>
                        </td>
                        <td>
                            {{ i.quantity }}
                        </td>
                        <td>
                            AED {{ i.quantity * i.amount }}
                        </td>

                    </tr>
                </tbody>
            </table>
            <EstimationDisplay :estimation="totalAmount" :expiry="quoteData.expiry_date"
                :validity="quoteData.valid_days" />
            <div>
                <h5 class="text-center mb-3 font-weight-bold text-success mt-4">Schedule Device Inspection Date</h5>
                <div class="d-flex align-items-center justify-content-center date__mob">
                    <div class="d-flex mx-3 w-100 justify-content-end cal-mob">
                        <div class="image-box mx-3" @click="toggleDatePicker">
                            <img :src="cal" alt="calendar">
                        </div>
                        <div class="d-flex flex-column">
                            <b-form-datepicker v-model="dateVal" @input="updateDate" class=""
                                :placeholder="'Select Date'" @blur="closeDatePicker" @click.stop></b-form-datepicker>
                            <span class="sub-text">Date and Day</span>
                            <p v-if="isSubmitted && errors.date" class="text-danger">{{ errors.date }}</p>
                        </div>
                    </div>

                    <div class="d-flex mx-3 w-100 justify-content-start">
                        <div class="image-box mx-3">
                            <img :src="time" alt="time">
                        </div>
                        <div class="d-flex flex-column">
                            <div class="d-flex time__mob">
                                <div class="mx-2 mb-2">
                                    <b-form-timepicker v-model="t1" locale="en"></b-form-timepicker>

                                </div>
                                <div>
                                    <b-form-timepicker v-model="t2" locale="en"></b-form-timepicker>

                                </div>
                            </div>
                            <span class="sub-text">Time Zone - Gulf Standard Time</span>
                            <p v-if="isSubmitted && errors.time" class="text-danger">{{ errors.time }}</p>
                        </div>
                    </div>
                </div>
                <h5 class="text-center mb-3 font-weight-bold text-success mt-4">Add Device Inspection Address</h5>
                <!-- Date Picker Component -->
                <div class="d-flex justify-content-center align-items-center mob-view">
                    <div class="d-flex flex-column mx-5">


                    </div>
                    <div class="d-flex flex-column">

                        <div class="d-flex">
                            <div class="coolinput mx-2 ">
                                <label for="input" class="text">Area/District*</label>
                                <b-form-input placeholder="Area/District" v-model="userArea"
                                    class="input"></b-form-input>
                                <p v-if="isSubmitted && errors.area" class="text-danger">{{ errors.area }}</p>
                            </div>
                            <div class="coolinput ">
                                <label for="input" class="text">State*</label>
                                <b-form-input placeholder="state" v-model="userState" class="input"></b-form-input>
                                <p v-if="isSubmitted && errors.state" class="text-danger">{{ errors.state }}</p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="coolinput p-2 w-100">
                                <label for="input" class="text">Full Address*</label>
                                <b-form-textarea placeholder="Address" v-model="useradd" class="input" rows="3"
                                    max-rows="6"></b-form-textarea>
                                <p v-if="isSubmitted && errors.address" class="text-danger">{{ errors.address }}</p>
                            </div>

                        </div>
                    </div>


                </div>


                <div class="divider"></div>


                <div class="d-flex align-items-center justify-content-center mt-3">
                    <b-button class="mx-3 approve" @click="handlesubmit()" :disabled="isLoading">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                        <span v-if="!isLoading">Approve Device Inspection</span>
                    </b-button>
                    <b-button class="mx-3 cancel" @click="cancelSchedule()">
                        <span v-if="isCancelLoading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                        <span v-if="!isCancelLoading">Cancel Quotation</span>
                    </b-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import EstimationDisplay from '../components/b2b/EstimationDisplay.vue';
import cal from '../assets/SVG/Calendar.svg';
import time from '../assets/SVG/time.svg';
import api from '../api'

export default {
    components: { EstimationDisplay },
    data() {
        return {
            deviceList: [{ "id": 444562, "category_name": "Mobile", "brand_name": "Apple", "model_name": "iPhone 13 Pro 256GB", "grade": "a", "price": 1190, "quantity": 1 }],
            estimation: 22500,
            cal,
            time,
            dateVal: '',
            t1: '',
            t2: '',
            dataLoading: false,
            totalAmount: 0,
            openDatePicker: false,
            quoteId: null,
            quoteData: [],
            useradd: '',
            userState: '',
            userArea: '',
            errors: {
                date: '',
                time: '',
                area: '',
                state: '',
                address: ''
            },
            isSubmitted: false,
            isLoading: false,
            isCancelLoading: false


        };
    },
    computed: {
        formattedDate() {
            return this.value ? this.formatDate(this.value) : '';
        },

    },
    methods: {
        toggleDatePicker() {
            this.openDatePicker = !this.openDatePicker;
        },
        updateDate(date) {
            this.value = date;
            this.openDatePicker = false;
        },
        closeDatePicker() {
            this.openDatePicker = false;
        },
        formatDate(date) {

            return new Intl.DateTimeFormat("en-US", {
                weekday: "long",
                month: "long",
                day: "numeric",
            }).format(new Date(date));
        },
        convertTo12HourFormat(time) {
            const [hours, minutes] = time.split(':');
            let hour = parseInt(hours, 10);
            const ampm = hour >= 12 ? 'PM' : 'AM';


            if (hour > 12) {
                hour -= 12;
            } else if (hour === 0) {
                hour = 12;
            }

            return `${hour}:${minutes} ${ampm}`;
        },
        validateForm() {
            let isValid = true;


            this.errors = {
                date: '',
                time: '',
                area: '',
                state: '',
                address: ''
            };


            if (!this.dateVal) {
                this.errors.date = 'Date is required.';
                isValid = false;
            }


            if (!this.t1 || !this.t2) {
                this.errors.time = 'Both time fields are required.';
                isValid = false;
            }


            if (!this.userArea) {
                this.errors.area = 'Area is required.';
                isValid = false;
            }


            if (!this.userState) {
                this.errors.state = 'State is required.';
                isValid = false;
            }


            if (!this.useradd) {
                this.errors.address = 'Full address is required.';
                isValid = false;
            }

            return isValid;
        },
        clearForm: function () {
            this.dateVal = '';
            this.userArea; '';
            this.useradd = '';
            this.userState = '';
            this.t1 = '';
            this.t2 = '';
        },

        handlesubmit: function () {
            this.isSubmitted = true;
            let address = [];
            if (this.validateForm()) {
                this.isLoading = true;
                address.push({
                    area: this.userArea,
                    state: this.userState,
                    fulladdress: this.useradd
                })
                const inputs = {
                    date: this.dateVal,
                    time: `${this.convertTo12HourFormat(this.t1)} - ${this.convertTo12HourFormat(this.t2)}`,
                    quote_id: this.quoteId,
                    address: address
                }
                api.request(process.env.VUE_APP_Create_Schedule, inputs).then((response) => {
                    if (response.data.result && typeof response.data.result === 'object') {
                        console.log(response.data.result.schedule_id)
                        if (response.data.result.schedule_id) {


                            setTimeout(() => {
                                this.$bvToast.toast('Scheduled Successfully!', {
                                    title: 'Success',
                                    variant: 'success',
                                    toaster: 'b-toaster-top-right'
                                })
                                this.isLoading = false;
                            }, 750)
                            setTimeout(() => {
                                this.clearForm();
                                this.$router.push('/b2b/schedule/success');
                            }, 1500);

                        } else {
                            this.isLoading = false;
                            this.clearForm();
                            this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
                                title: 'Error',
                                variant: 'danger',
                                toaster: 'b-toaster-top-right'
                            })
                        }

                    } else {
                        this.isLoading = false;
                        this.clearForm();
                        throw response.data
                    }
                }).catch(() => {
                    this.isLoading = false;
                    this.clearForm();
                    this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
                        title: 'Error',
                        variant: 'danger',
                        toaster: 'b-toaster-top-right'
                    })
                })
            }

        },

        get_Quote_Details: function () {
            const inputs = {
                id: this.quoteId,
            }
            this.dataLoading = true;
            api.request(process.env.VUE_APP_Get_Quote, inputs).then((response) => {
                if (response.data.result && typeof response.data.result === 'object') {

                    this.quoteData = response.data.result;
                    this.dataLoading = false;

                    response.data.result.devices.map((i) => (
                        this.totalAmount += i.total_amount
                    ))

                } else {
                    this.dataLoading = false;
                    throw response.data
                }
            })
        },

        cancelSchedule: function () {
            const inputs = {
                quote_id: this.quoteId,
            }
            this.isCancelLoading = true;
            api.request(process.env.VUE_APP_Cancel_Schedule, inputs).then((response) => {
                if (response.data.result && typeof response.data.result === 'object') {

                    if (response.data.result.status === "success") {


                        setTimeout(() => {
                            this.$bvToast.toast('Cancelled Successfully!', {
                                title: 'Success',
                                variant: 'success',
                                toaster: 'b-toaster-top-right'
                            })
                            this.isCancelLoading = false;
                        }, 750)
                        setTimeout(() => {
                            this.clearForm();
                            this.$router.push('/b2b/schedule/cancel');
                        }, 1500);

                    } else {
                        this.isCancelLoading = false;
                        this.clearForm();
                        this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
                            title: 'Error',
                            variant: 'danger',
                            toaster: 'b-toaster-top-right'
                        })
                    }

                } else {
                    this.isCancelLoading = false;
                    this.clearForm();
                    throw response.data
                }
            }).catch(() => {
                this.isCancelLoading = false;
                this.clearForm();
                this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
            })
        },

        printDate: function () {
            console.log('date', this.dateVal)
            console.log('time-1', this.convertTo12HourFormat(this.t1))
            console.log('time-2', this.convertTo12HourFormat(this.t2))
        }
    },

    mounted() {
        this.quoteId = this.$route.query.quote;
        this.get_Quote_Details();


    }
};
</script>

<style scoped>
.quote-summary {
    width: 100%;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #2c3e50;
    font-weight: bold;
    text-align: left;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 12px;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 1);
    text-align: center !important;
}

th {
    background-color: #006400;
    color: #fff;
    text-align: center !important;
}

.image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 1rem;
    background-color: #8ce2ba;
    border-radius: 50%;
}

.clickable-text {
    cursor: pointer;
    color: #006400;

    text-decoration: underline;

}


.position-absolute {
    position: absolute;
    top: 100%;

    z-index: 10000;

}

.date-picker {
    display: none;

}

.sub-text {
    font-size: 14px;
    color: #010D1C80;
}

.coolinput {
    display: flex;
    flex-direction: column;
    position: static;
    /* width: 100%; */
    min-width: 250px;
    margin-top: 10px;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

.text-danger {
    font-size: 12px;
    margin-top: 5px;
}

.coolinput label.text {
    font-size: 0.75rem;
    color: #787878;
    font-weight: 700;
    position: relative;
    top: 0.5rem;
    margin: 0 0 0 7px;
    padding: 0 3px;
    background: #fff;
    width: fit-content;
}

.coolinput input[type=text].input {
    padding: 11px 10px;
    font-size: 0.75rem;
    border: 1px #787878 solid;
    border-radius: 8px;
    height: 100%;

}

.divider {
    height: .5px;
    width: 100%;
    background-color: #dbd8d8;
    margin-top: 20px;
}

.coolinput input[type=text].input:focus {
    outline: none;
}

.coolinput .input::-webkit-scrollbar {
    display: none;

}

.approve {
    background-color: #006400;
    border-radius: 12px;
    border: none;
    padding: .5rem 2rem;
}

.cancel {
    background-color: transparent;
    color: #3E3D3780;
    border: 1px solid #3E3D3780;
    border-radius: 12px;
    padding: .5rem 2rem;
}


@media (max-width: 768px) {

    .mob-view {
        flex-direction: column;
    }

    .date__mob {
        flex-direction: column;
        justify-content: center;
    }

    .time__mob {
        flex-direction: column;
    }

    .cal-mob {
        justify-content: start !important;
        ;
    }
}
</style>