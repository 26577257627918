<template>
    <b-navbar toggleable type="light" variant="light" class="mt-3">
        <b-navbar-brand href="#">Choose Model <b v-if="model" style="background-color: rgba(1, 105, 56, 0.4);color: rgba(1, 105, 56, 0.8);border-radius: 10px;padding: 2px 6px 2px 6px ;">{{ model }}</b></b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse3">
            <template #default="{ expanded }">
                <b-icon v-if="expanded" icon="chevron-bar-up">
                    <BIconChevronUp />
                </b-icon>
                <b-icon v-else icon="chevron-bar-down">
                    <BIconChevronDown />
                </b-icon>
            </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse3" is-nav>
            <div class="d-flex">
                <div class="p-2">
                    <label>Series</label>
                    <b-form-select id="input-3" v-model="series_selected" @change="getModels" class=" inp" required>
                        <b-form-select-option :value="null">Please select series</b-form-select-option>
                        <b-form-select-option v-for="i in series_list" :value="i.name">{{ i.name
                            }}</b-form-select-option>
                    </b-form-select>
                </div>
                <div class="p-2">
                    <label>Model</label>
                    <b-form-select id="input-3" v-model="model_selected" @change="getRom" class=" inp" required>
                        <b-form-select-option :value="null">Please select model</b-form-select-option>
                        <b-form-select-option v-for="i in model_list" :value="i.name">{{ i.name
                            }}</b-form-select-option>
                    </b-form-select>
                </div>
                <div class="p-2">
                    <label>Rom</label>
                    <b-form-select id="input-3" v-model="rom_selected" @change="Modelclick" class=" inp" required>
                        <b-form-select-option :value="null">Please select rom</b-form-select-option>
                        <b-form-select-option v-for="i in rom_list" :value="i">{{ i.name
                            }}</b-form-select-option>
                    </b-form-select>
                </div>
            </div>
        </b-collapse>
    </b-navbar>

</template>

<script>
import api from '../../api'
import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'

export default {
    name: 'CategorySelect',
    props: {
        category_name: {},
        brand_name: {}

    },
    data() {
        return {
            series_selected: null,
            model_selected: null,
            rom_selected: null,
            series_list: null,
            model_list: null,
            rom_list: null,
            model:null


        }
    },
    components: {
        BIconChevronUp,
        BIconChevronDown
    },
    computed: {
    },
    watch: {
        brand_name: {
            handler(newValue) {
                this.getSeries();
            },
            immediate: true // Run this watcher immediately upon component creation
        }
    },
    methods: {
        getSeries: function () {
            const inputs = {

                'cate': this.category_name,
                'brand': this.brand_name,
                'location': 'United Arab Emirates'

            }
            api.request(process.env.VUE_APP_Get_Series_data, inputs).then((response) => {
                if (response.data.result && typeof response.data.result === 'object') {
                    this.series_list = response.data.result
                } else {
                    throw response.data
                }
            }).catch(() => {
                this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
            })
        },
        getModels: function () {
            console.log(this.category_name)
            console.log(this.brand_name)
            console.log(this.series_selected)
            const inputs = {
                'cate': this.category_name,
                'brand': this.brand_name,
                'location': 'United Arab Emirates',
                'series': this.series_selected,
            }
            api
                .request(process.env.VUE_APP_Get_Model_Data, inputs)
                .then((response) => {
                    var data = response.data
                    if (data.errorcode) {
                        return
                    }
                    if (data.result) {
                        this.model_list = data.result
                    }
                })
                .catch((error) => {
                    console.log('What happened? ' + error)
                })
        },
        getRom: function () {
            const inputs = {
                'cate': this.category_name,
                'brand': this.brand_name,
                'location': 'United Arab Emirates',
                'model': this.model_selected
            }
            api
                .request(process.env.VUE_APP_Get_Rom_Data, inputs)
                .then((response) => {
                    var data = response.data
                    if (data.errorcode) {
                        return
                    }
                    if (data.result) {
                        this.rom_list = data.result
                    }
                })
                .catch((error) => {
                    // console.log('What happened? ' + error)
                })
        },
        Modelclick: function () {
            this.model = (this.model_selected+ ' ' +this.rom_selected.name)
            this.$emit('updatemodel', { id: this.rom_selected.id, name: (this.model_selected+ ' ' +this.rom_selected.name) });
            this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse3')
            this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse4')

        }
    },
    mounted() {
        this.getSeries()
    }
};
</script>

<style scoped>
.category-icon {
    cursor: pointer;
    color: green;
}

.category-icon i {
    font-size: 2rem;
}
</style>