<template>
  <div class="container card mt-5 p-3">
    <div class="row">
      <div class="col-md-12 mt-3 d-flex justify-content-between align-items-center text-center">
        <h6>Trade-in</h6>
        <h4>Build Your Quote</h4>
        <div class="d-flex align-items-center">
          <h6 class="upload-text mx-2">Upload in bulk using <span class="template-text">this template</span> </h6>
          <div>

            <b-button class="upload-btn" @click="triggerFileInput()">Upload the file <img class="mx-2"
                src="../assets/Download.png" alt="icn" width="20px" /></b-button>
            <input ref="fileInput" type="file" style="display: none;" @change="handleFileChange" />

          </div>
        </div>

      </div>
      <div v-if="this.fileName" class="file-text">
        <p class="file-info">Selected file: <strong>{{ fileName }}</strong></p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-8">
        <div class="card h-100 text-center">
          <div class="card-body">
            <CategorySelect @updatecategory="handlecategory" class="green-border" />
            <BrandSelect :category_id="category_id" @updatebrand="handlebrand" class="green-border" />
            <ModelSelect :category_name="category_name" :brand_name="brand_name" @updatemodel="handlemodel"
              class="green-border" />
            <ConditionSelect :model_id="model_id" @updatecondition="handlecondition" class="green-border" />
            <QuantitySelect @updatequantity="handlequantity" class="green-border" :priceUpdate="updatePriceOnChange" />
          </div>
          <div class="card-footer bg-white d-flex justify-content-end">
            <button class="next_btn" @click="handlesubmit()">
              Next <i class="bi bi-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <!-- <BulkUpload /> -->
        <div class="card h-100 text-center">
          <div class="card-body">
            <DeviceInfo class="" :category_name="category_name" :brand_name="brand_name" :model_name="model_name"
              :quantity="quantity" :condition="condition" :device_info="device_info" />
            <EstimationBox class="est " :quantity="this.estimation" :condition="condition" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategorySelect from '../components/b2b/CategorySelect.vue';
import BrandSelect from '../components/b2b/BrandSelect.vue';
import ModelSelect from '../components/b2b/ModelSelect.vue';
import ConditionSelect from '../components/b2b/ConditionSelect.vue';
import QuantitySelect from '../components/b2b/QuantitySelect.vue';
import EstimationBox from '../components/b2b/EstimationBox.vue';
import DeviceInfo from '../components/b2b/DeviceInfo.vue';

export default {
  data() {
    return {
      category_name: null,
      category_id: null,
      brand_name: null,
      brand_id: null,
      model_id: null,
      model_name: null,
      condition: null,
      quantity: 0,
      device_info: this.$store.state.deviceList || [],
      fileName: null,
      estimation: 0,

    }
  },
  computed: {
    deviceList: function () {
      return this.$store.state.deviceList
    },
    totalEstimation() {
      const list = this.deviceList;
      return list && list.reduce((total, device) => {
        return total + device.quantity * device.price;
      }, 0);
    },
  },
  components: {
    CategorySelect,
    BrandSelect,
    ModelSelect,
    ConditionSelect,
    QuantitySelect,
    EstimationBox,
    DeviceInfo
  },
  methods: {
    handlecategory(category) {
      this.category_name = category.name
      this.category_id = category.id
    },
    handlebrand(brand) {
      this.brand_name = brand.name
      this.brand_id = brand.id
    },
    handlemodel(model) {
      this.model_id = model.id
      this.model_name = model.name
    },
    handlecondition(condition) {
      this.condition = condition
      console.log(condition)
    },
    handlequantity(quantity) {
      this.quantity = quantity.count

    },
    updatePriceOnChange(type) {
      if (this.estimation > 0) {
        if (type === 1) {

          this.estimation += (this.condition.price)
        }
        else {
          this.estimation -= (this.condition.price)
        }

        console.log('final price', this.estimation)
      }
      else {
        this.estimation = this.condition.price * this.quantity
      }

    },
    handlesubmit() {

      if (this.fileName) {
        localStorage.setItem('isFile', 1)
        this.$store.commit('SET_DEVICE_LIST', null)
        this.$router.push({ name: 'B2BQuote' })
      }
      else {
        localStorage.setItem('fileImportList', null)
        localStorage.setItem('isFile', 0)
      }
      this.device_info.push({
        id: this.model_id,
        category_name: this.category_name,
        brand_name: this.brand_name,
        model_name: this.model_name,
        grade: this.condition.grade,
        price: this.condition.price,
        quantity: this.quantity
      })

      this.$store.commit('SET_DEVICE_LIST', this.device_info)

      this.$router.push({ name: 'B2BQuote' })

    },

    triggerFileInput() {

      this.$refs.fileInput.click();
    },


    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileName = file.name;

      }
    }
  },
  mounted() {
    this.estimation = this.totalEstimation;
  }
};
</script>

<style scoped>
h4,
h6 {
  color: rgba(1, 105, 56, 1) !important;
}

.est {
  display: flex;
  flex-direction: column;
  height: 20vh;
  /* or set a specific height for your container */
  justify-content: flex-end;
}

.green-border {
  border: 2px solid green;
  margin-bottom: 1.5rem;
  border-radius: 10px;
}

.next_btn {
  background-color: transparent;
  width: 4.5rem !important;
  height: 1.7rem !important;
  border-radius: 1rem;
  color: #6b7280;
  border: 1px solid #e5e7eb;
}

.upload-text {
  color: #000 !important;
}

.template-text {
  color: rgba(1, 105, 56, 1);
  text-decoration: underline;
}

.upload-btn {
  background-color: transparent;
  border: 1px solid #AEB2BF80;
  color: rgba(1, 105, 56, 1);
  border-radius: 1rem;
  display: flex;
  align-items: center;
}

.file-text {
  margin-top: 1rem;
  padding: 0 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>