<template>
  <div class="action-buttons">
    <button class="add-devices" @click="addDevices" :disabled="isFileUpload === '1'">Add Devices</button>
    <button class="confirm ml-3" @click="confirmQuote">Confirm</button>
  </div>
</template>

<script>
export default {
  computed: {
    isFileUpload: function () {
      return localStorage.getItem('isFile')
    },
  },
  methods: {
    addDevices() {
      this.$router.push({ name: 'B2B' })
    },
    confirmQuote() {
      this.$router.push({ name: 'B2BDetails' })
    },
  },
};
</script>

<style scoped>
.action-buttons {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

button.add-devices {
  background-color: transparent;
  width: 8.0rem !important;
  height: 2.0rem !important;
  border-radius: 1rem;
  color: #000000;
  border: 1px solid #000000;
}

button.confirm {
  background-color: transparent;
  width: 8.0rem !important;
  height: 2.0rem !important;
  border-radius: 1rem;
  color: #000000;
  border: 1px solid #000000;
}

button.add-devices:hover,
button.confirm:hover {
  background-color: #004d00;
  color: white;
}

button.add-devices:not(:disabled):hover,
button.confirm:not(:disabled):hover {
  background-color: #004d00;
  color: white;
}

/* Optional: Styling for disabled button */
button.add-devices:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
