<template>
    <b-navbar toggleable type="light" variant="light" class="mt-3">
        <b-navbar-brand href="#">Choose Quantity</b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse5">
            <template #default="{ expanded }">
                <b-icon v-if="expanded" icon="chevron-bar-up">
                    <BIconChevronUp />
                </b-icon>
                <b-icon v-else icon="chevron-bar-down">
                    <BIconChevronDown />
                </b-icon>
            </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse5" is-nav>
            <div class="d-flex justify-content-center">
                <div class="counter">
                    <button class="button1" @click="decrement" :disabled="count <= 1">-</button>
                    <span>{{ count }}</span>
                    <button class="button1" @click="increment">+</button>
                </div>
            </div>
        </b-collapse>
    </b-navbar>

</template>

<script>

import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'

export default {
    name: 'QuantitySelect',
    props: ['priceUpdate'],
    data() {
        return {
            count: 0
        }
    },
    components: {
        BIconChevronUp,
        BIconChevronDown
    },
    methods: {
        increment() {
            this.count++;
            this.$emit('updatequantity', { count: this.count });
            this.priceUpdate(1);

        },
        decrement() {
            if (this.count > 1) {
                this.count--;
                this.$emit('updatequantity', { count: this.count });
                this.priceUpdate(2);
            }
        },
    }
};
</script>

<style scoped>
.counter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 150px;
}

.button1 {
    border: 1px solid #28a745;
    background-color: white;
    color: #28a745;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    cursor: pointer;
}

button:disabled {
    color: #ddd;
    border-color: #ddd;
    cursor: not-allowed;
}

span {
    margin: 0 10px;
    font-size: 18px;
}
</style>