<template>
    <b-navbar toggleable type="light" variant="light" class="mt-3">
        <b-navbar-brand href="#">Choose Category <b v-if="category"
                style="background-color: rgba(1, 105, 56, 0.4);color: rgba(1, 105, 56, 0.8);border-radius: 10px;padding: 2px 6px 2px 6px ;">{{
            category }}</b></b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse">
            <template #default="{ expanded }">
                <b-icon v-if="expanded" icon="chevron-bar-up">
                    <BIconChevronUp />
                </b-icon>
                <b-icon v-else icon="chevron-bar-down">
                    <BIconChevronDown />
                </b-icon>
            </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse" is-nav>
            <b-row>
                <b-col v-for="i in categoryList" :key="i.id">
                    <a href="#" @click="cateclick(i.id, i.name)">
                        <img :src="'data:image/png;base64,' + i.image" height="80" />
                        <div>
                            <h5 class="mt-2" style="color: black;"><b>{{ i.name }}</b></h5>

                        </div>
                    </a>
                </b-col>
            </b-row>
        </b-collapse>
    </b-navbar>

</template>

<script>
import api from '../../api'
import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'

export default {
    name: 'CategorySelect',
    data() {
        return {
            category: null
        }
    },
    components: {
        BIconChevronUp,
        BIconChevronDown
    },
    computed: {
        categoryList: function () {
            return this.$store.state.categoryList
        },
    },
    methods: {
        get_categories: function () {
            const inputs = {
                'reuse_sell': true
            }
            api.request(process.env.VUE_APP_Get_Categories, inputs).then((response) => {
                if (response.data.result && typeof response.data.result === 'object') {

                    const filteredCategories = response.data.result[0].filter(category =>
                        category.name !== 'Smartwatches' && category.name !== 'Headphones & wireless audio'
                    );
                    console.log("***************Data mine************", filteredCategories)
                    console.log('************old********', response.data.result)
                    this.$store.commit('SET_CATEGORY_LIST', filteredCategories)
                    this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse')
                } else {
                    throw response.data
                }
            }).catch(() => {
                this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
            })
        },
        cateclick: function (id, name) {
            this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse')
            this.$emit('updatecategory', { id: id, name: name });
            this.category = name
            this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse2')

        }
    },
    mounted() {
        this.get_categories()
    }
};
</script>

<style scoped></style>