<template>
    <b-navbar toggleable type="light" variant="light" class="mt-3">
        <b-navbar-brand href="#">Choose Brand  <b v-if="brand" style="background-color: rgba(1, 105, 56, 0.4);color: rgba(1, 105, 56, 0.8);border-radius: 10px;padding: 2px 6px 2px 6px ;">{{ brand }}</b></b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse2">
            <template #default="{ expanded }">
                <b-icon v-if="expanded" icon="chevron-bar-up">
                    <BIconChevronUp />
                </b-icon>
                <b-icon v-else icon="chevron-bar-down">
                    <BIconChevronDown />
                </b-icon>
            </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse2" is-nav>
            <b-row >
              <b-col v-for="i in brandList" :key="i.id"  >
                <a href="#"  @click="brandclick(i.id, i.name)">
                    <img :src="'data:image/png;base64,' + i.image" height="80" />
                    <div>
                        <h6 class="mt-2" style="color: black;"><b>{{ i.name }}</b></h6>
                    </div>  
                </a>
              </b-col>
            </b-row>
        </b-collapse>
    </b-navbar>

</template>

<script>
import api from '../../api'
import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'

export default {
    name: 'CategorySelect',
    props: {
        category_id: {}
    },
    data() {
        return {
            brand:null
        }
    },
    components: {
        BIconChevronUp,
        BIconChevronDown
    },
    computed: {
        brandList: function () {
            return this.$store.state.brandList
        },
    },
    watch: {
        category_id: {
            handler(newValue) {
                this.getBrands(newValue);
            },
            immediate: true // Run this watcher immediately upon component creation
        }
    },
    methods: {
        getBrands: function (newValue) {
            const inputs = {
                'cate_id': newValue
            }
            api.request(process.env.VUE_APP_Get_Brand_Data, inputs).then((response) => {
                if (response.data.result && typeof response.data.result === 'object') {
                    this.$store.commit('SET_BRAND_LIST', response.data.result)
                } else {
                    throw response.data
                }

            }).catch(() => {
                this.$bvToast.toast('We are not able to process this request right now. Please try again laterss.', {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
            })
        },
        brandclick: function (id, name) {
            this.brand = name
            this.$emit('updatebrand', { id: id, name: name });
            this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse2')
            this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse3')

        }
    },

};
</script>

<style scoped>
.category-icon {
    cursor: pointer;
    color: green;
}

.category-icon i {
    font-size: 2rem;
}
</style>