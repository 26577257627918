<template>
  <div class="container card mt-5 p-3">
    <div class="row">
      <div class="col-md-12 mt-3 text-center">
        <h4>Build Your Quote</h4>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-8">
        <div class="card h-100 text-center">
          <div class="card-body">
            <div class=" mt-3 ">
              <div class="text-left">
                <div class="d-flex flex-column align-items-center p-3">
                  <div class="d-flex align-items-center">
                    <h5 class="text-success mb-0 me-3">Are you generating a quote for yourself?</h5>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input green-radio mx-2" type="radio" name="quoteFor" id="quoteYes"
                        value="yes" v-model="isGeneratingForYourself">
                      <label class="form-check-label" for="quoteYes">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input green-radio mx-2" type="radio" name="quoteFor" id="quoteNo"
                        value="no" v-model="isGeneratingForYourself">
                      <label class="form-check-label" for="quoteNo">No</label>
                    </div>
                  </div>

                  <!-- //fields -->
                  <div class="d-flex w-100">
                    <div class="d-flex flex-column w-100 align-items-center mt-3">
                      <h5 class="text-success mb-0 me-3">Quote Generated For</h5>
                      <div class="coolinput p-2">
                        <label for="input" class="text">Company Name*</label>
                        <b-form-input placeholder="Company" v-model="usercompany" class="input"></b-form-input>

                      </div>
                      <div class="coolinput p-2">
                        <label for="input" class="text">Full Address*</label>
                        <b-form-input placeholder="Address" v-model="useradd" class="input"></b-form-input>

                      </div>
                      <div class="coolinput p-2">
                        <label for="input" class="text">Full Name*</label>
                        <b-form-input placeholder="Name" v-model="userName" class="input"></b-form-input>

                      </div>
                      <div class="coolinput p-2">
                        <label for="input" class="text">Mobile Number*</label>
                        <b-form-input placeholder="Number" v-model="userMobile" class="input"></b-form-input>

                      </div>
                      <div class="coolinput p-2">
                        <label for="input" class="text">Email Address*</label>
                        <b-form-input placeholder="Email" v-model="userEmail" class="input"></b-form-input>

                      </div>
                    </div>
                    <div v-if="isGeneratingForYourself === 'no'"
                      class="d-flex flex-column w-100 align-items-center mt-3">
                      <h5 class="text-success mb-0 me-3">Quote Generated By</h5>
                      <div class="coolinput p-2">
                        <label for="input" class="text">Company Name*</label>
                        <b-form-input placeholder="Company" v-model="usercompany2" class="input"></b-form-input>

                      </div>
                      <div class="coolinput p-2">
                        <label for="input" class="text">Full Address*</label>
                        <b-form-input placeholder="Address" v-model="useradd2" class="input"></b-form-input>

                      </div>
                      <div class="coolinput p-2">
                        <label for="input" class="text">Full Name*</label>
                        <b-form-input placeholder="Name" v-model="userName2" class="input"></b-form-input>

                      </div>
                      <div class="coolinput p-2">
                        <label for="input" class="text">Mobile Number*</label>
                        <b-form-input placeholder="Number" v-model="userMobile2" class="input"></b-form-input>

                      </div>
                      <div class="coolinput p-2">
                        <label for="input" class="text">Email Address*</label>
                        <b-form-input placeholder="Email" v-model="userEmail2" class="input"></b-form-input>

                      </div>
                    </div>
                  </div>





                  <!-- fields end -->

                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-white d-flex justify-content-end">
            <button class="next_btn" @click="handlesubmit()">
              Submit <i class="bi bi-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <!-- <BulkUpload /> -->
        <div class="card h-100 text-center">
          <div class="card-body">
            <DeviceInfo :category_name="category_name" :brand_name="brand_name" :model_name="model_name"
              :quantity="quantity" :condition="condition" :device_info="device_info" />
            <EstimationBox class="est" :quantity="quantity" :condition="condition" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategorySelect from '../components/b2b/CategorySelect.vue';
import BrandSelect from '../components/b2b/BrandSelect.vue';
import ModelSelect from '../components/b2b/ModelSelect.vue';
import ConditionSelect from '../components/b2b/ConditionSelect.vue';
import QuantitySelect from '../components/b2b/QuantitySelect.vue';
import EstimationBox from '../components/b2b/EstimationBox.vue';
import DeviceInfo from '../components/b2b/DeviceInfo.vue';
import api from '../api'

export default {
  data() {
    return {
      category_name: null,
      category_id: null,
      brand_name: null,
      brand_id: null,
      model_id: null,
      model_name: null,
      condition: null,
      quantity: null,
      device_info: this.$store.state.deviceList || [],
      file_data_info: [],
      userName: this.$store.state.signUpForm ? this.$store.state.signUpForm.name : '',
      useradd: this.$store.state.signUpForm ? this.$store.state.signUpForm.useradd : '',
      userEmail: this.$store.state.signUpForm ? this.$store.state.signUpForm.email : '',
      userMobile: this.$store.state.signUpForm ? this.$store.state.signUpForm.mobile : '',
      userstate: this.$store.state.signUpForm ? this.$store.state.signUpForm.state : '',
      usercompany: this.$store.state.signUpForm ? this.$store.state.signUpForm.usercompany : '',
      userName2: '',
      useradd2: '',
      userEmail2: '',
      userMobile2: '',
      userstate2: '',
      usercompany2: '',
      isGeneratingForYourself: 'yes',

    }
  },
  components: {
    CategorySelect,
    BrandSelect,
    ModelSelect,
    ConditionSelect,
    QuantitySelect,
    EstimationBox,
    DeviceInfo
  },
  computed: {
    deviceList: function () {
      return this.$store.state.deviceList
    },
    fileDevList: function () {
      const fileList = localStorage.getItem('fileImportList');
      return fileList ? JSON.parse(fileList) : [];
    },
    isFileUpload: function () {
      return localStorage.getItem('isFile')
    },

  },
  methods: {
    totalEstimation() {
      const list = this.isFileUpload === '1' ? this.fileDevList : this.deviceList;

      // Ensure list is an array
      if (!Array.isArray(list)) {
        console.error('Expected list to be an array, but got:', typeof list);
        return 0; // Return 0 if list is not an array
      }

      return list.reduce((total, device) => {
        // Ensure device has the required properties (quantity and price)
        if (device && typeof device.quantity === 'number' && typeof device.price === 'number') {
          console.log('fgfgfg', total + device.quantity * device.price)
          return total + device.quantity * device.price;
        }

        return total;
      }, 0);
    },

    handlesubmit: function () {
      let generated_by = [];
      let generated_for = [];
      if (this.isGeneratingForYourself === 'yes') {
        let address = {
          address: this.useradd,
          email: this.userEmail,
          mobile: this.userMobile,
          company: this.usercompany,
          name: this.userName
        }
        generated_by.push(address);
        generated_for.push(address);
      }
      else if (this.isGeneratingForYourself === 'no') {
        let address_for = {
          address: this.useradd,
          email: this.userEmail,
          mobile: this.userMobile,
          company: this.usercompany,
          name: this.userName
        }
        let address_by = {
          address: this.useradd2,
          email: this.userEmail2,
          mobile: this.userMobile2,
          company: this.usercompany2,
          name: this.userName2
        }
        generated_by.push(address_by);
        generated_for.push(address_for);
      }

      let isFileImport = localStorage.getItem("isFile") === '1';
      const inputs = {
        "devices": isFileImport ? this.file_data_info : this.device_info,
        "generated_by": generated_by,
        "generated_for": generated_for,
        "date": new Date().toISOString().split('T')[0]
      }
      api.request(process.env.VUE_APP_B2B, inputs).then((response) => {
        if (response.data.result && typeof response.data.result === 'object') {
          console.log(response.data.result.inquiry_id)
          if (response.data.result.inquiry_id) {
            this.$router.push({ name: 'B2BEND', params: { 'id': response.data.result.inquiry_id } })
            this.$store.commit('SET_DEVICE_LIST', null)

          } else {
            this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
              title: 'Error',
              variant: 'danger',
              toaster: 'b-toaster-top-right'
            })
          }

        } else {
          throw response.data
        }
      }).catch(() => {
        this.$bvToast.toast('We are not able to process this request right now. Please try again later.', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        })
      })
    },
  },


  mounted() {
    this.quantity = this.totalEstimation()
    this.file_data_info = this.fileDevList;
  }
};
</script>

<style scoped>
h4,
h5 {
  color: rgba(1, 105, 56, 1) !important;
}

.est {
  display: flex;
  flex-direction: column;
  height: 20vh;
  /* or set a specific height for your container */
  justify-content: flex-end;
}

.coolinput {
  display: flex;
  flex-direction: column;
  position: static;
  width: 100%;
  margin-top: 10px;
}

.coolinput label.text {
  font-size: 0.75rem;
  color: #787878;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  background: #fff;
  width: fit-content;
}

.coolinput input[type=text].input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border: 1px #787878 solid;
  border-radius: 8px;
  height: 100%;

}

.coolinput input[type=text].input:focus {
  outline: none;
}

.next_btn {
  background-color: transparent;
  width: 6.0rem !important;
  height: 2.0rem !important;
  border-radius: 1rem;
  color: rgba(1, 105, 56, 1);
  border: 1px solid #e5e7eb;
}

.green-radio {
  border: 2px solid #28a745;
  background-color: #28a745;
}

.green-radio:checked {
  background-color: #28a745;
  border-color: #28a745;
}

.green-radio:checked::before {
  background-color: white;
}


.form-check-label {
  color: #28a745;
}
</style>