<template>
  <div>
    <div class="card p-3 text-left green-border" v-if="category_name">
      <ul class="no-bullets ">
        <li>{{ category_name }}</li>
        <li>{{ brand_name }}</li>
        <li>{{ model_name }}</li>
        <li v-if="condition">
          <b v-if="condition.grade === 'a'">Condition 1</b>
          <b v-if="condition.grade === 'b'">Condition 2</b>
          <b v-if="condition.grade === 'c'">Condition 3</b>
        </li>
        <li v-if="condition">AED {{ condition.price }}</li>
        <li v-if="quantity">Count {{ quantity }}</li>
      </ul>
    </div>
    <div class="card scrollable-container mt-3 green-border" v-if="deviceList">
      <span>Added Devices</span>
      <div class="card p-3 text-left mt-2 green-border" v-for="i in deviceList" :key="i.id">
        <ul class="no-bullets">
          <li>
            {{ i.category_name }}
          </li>
          <li> {{ i.brand_name }}</li>
          <li> {{ i.model_name }}</li>
          <li>
            <b v-if="i.grade === 'a'">Condition 1</b>
            <b v-if="i.grade === 'b'">Condition 2</b>
            <b v-if="i.grade === 'c'">Condition 3</b>
          </li>
          <li> AED {{ i.price }}</li>
          <li>Count {{ i.quantity }}</li>
        </ul>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'DeviceInfo',
  props: {
    category_name: {},
    brand_name: {},
    model_name: {},
    quantity: {},
    condition: {}


  },
  computed: {
    deviceList: function () {
      return this.$store.state.deviceList
    },
  }
};
</script>

<style scoped>
span {
  color: black;
}

.card {
  border: 1px solid #ddd;
  font-weight: 600;
  background-color: rgba(1, 105, 56, 1);
  color: white;

}

.no-bullets {
  list-style-type: none;
  padding: 0;
  /* Removes default padding */
  margin: 0;
  /* Removes default margin */
}

.scrollable-container {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background-color: #fff;
}

.green-border {
  border: 2px solid green;
  margin-bottom: 1.5rem;
  border-radius: 10px;
}
</style>