<template>
  <div class="quantity-selector">
    <button class="button1" @click="decreaseQuantity">-</button>
    <span>{{ localQuantity }}</span>
    <button class="button2" @click="increaseQuantity">+</button>
  </div>
</template>

<script>
export default {
  props: {
    quantity: {
      type: Number,
      required: true,
    },
    id: {}
  },
  data() {
    return {
      localQuantity: this.quantity,
    };
  },
  methods: {
    emitQuantityChange() {
      this.$emit('updateQuantity', {qty:this.localQuantity, id:this.id});
    },
    decreaseQuantity() {
      if (this.localQuantity > 1) {
        this.localQuantity--;
        this.emitQuantityChange();
      }
    },
    increaseQuantity() {
      this.localQuantity++;
      this.emitQuantityChange();
    },
  },
  watch: {
    quantity(newValue) {
      this.localQuantity = newValue; // Sync the local quantity with the prop
    },
  },
};
</script>

<style scoped>

.quantity-selector {
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;

}

button {
  width: 30px;
  height: 30px;
  font-size: 18px;
  background-color: #006400;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

span {
  margin: 0 10px;
  font-size: 18px;
}
.button1 {
    border: 1px solid #28a745;
    background-color: #ffffff;
    color: #28a745;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    cursor: pointer;
}
.button2 {
    border: 1px solid #28a745;
    background-color: #28a745;
    color: #ffffff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    cursor: pointer;
}

button:disabled {
    color: #ddd;
    border-color: #ddd;
    cursor: not-allowed;
}

span {
    margin: 0 10px;
    font-size: 18px;
}
</style>
