<template>
  <div>
    <div class="card p-3 text-left">
      <p>ESTIMATION</p>
      <p class="amount" v-if="quantity">AED {{ quantity }}</p>
      <p class="amount" v-else>AED 0</p>
    </div>
  </div>

</template>

<script>
export default {
  name: 'EstimationBox',
  props: {
    quantity: {},
    condition: {}

  },
};
</script>

<style scoped>
.card {
  border: 1px solid #ddd;
  font-weight: 800;
  border: 1px solid #ddd;
  font-weight: 600;
  background-color: rgba(252, 252, 252, 1);
  color: rgb(0, 0, 0);
  border: 2px solid black;
  margin-bottom: 1.5rem;
  border-radius: 10px;
}

.amount {
  font-size: larger;
  font-weight: 900;
}
</style>