export default {
  INITIALISE_STORE(state) {
    const userData = localStorage.getItem("userData");
    const language = localStorage.getItem("selectedLanguage");
    const deviceList = localStorage.getItem("deviceList");
    let fileImport = false;

    if (userData) {
      state.user = JSON.parse(userData);

      state.selectedLanguage = "en";

      console.log(state.selectedLanguage);

      state.deviceList = JSON.parse(deviceList);
    }
  },

  SET_FILE_IMPORT(state, value) {
    state.fileImport = value;
  },

  TOGGLE_LOADING(state) {
    state.callingAPI = !state.callingAPI;
  },
  TOGGLE_SEARCHING(state) {
    state.searching = state.searching === "" ? "loading" : "";
  },
  SET_USER(state, user) {
    state.user = user;
    localStorage.setItem("userData", JSON.stringify(user));
  },
  SET_PARTNER(state, partner) {
    state.partner = partner;
    localStorage.setItem("partnerData", JSON.stringify(partner));
  },
  UPDATE_USER_CONTACT(state, contact) {
    state.user.contact_address = contact;
    localStorage.setItem("userData", JSON.stringify(state.user));
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_SERVICE(state, activeService) {
    state.activeService = activeService;
  },
  SET_SERVICE_TYPE(state, activeServiceType) {
    state.activeServiceType = activeServiceType;
    // state.selectedModel = null
    // state.selectedROM = null
    switch (activeServiceType.value) {
      case "repair":
        state.taskList[2].label = "Select Defects";
        break;
      case "protection":
        state.taskList[2].label = "Select Premium";
        state.taskList[7].label = "Gadget Verification";
        break;
      case "data recovery":
        state.taskList[2].label = "Choose Data to Recover";
        state.taskList[7].label = "Gadget Evaluation";
        break;
    }
  },
  RESET_TASK_LIST(state) {
    const taskList = [
      {
        step: 1,
        label: "Choose Service",
      },
      {
        step: 2,
        label: "Product Selection",
      },
      {
        step: 3,
        label: "Select Defects",
      },
      {
        step: 4,
        label: "Service Delivery Request",
      },
      {
        step: 5,
        label: "Estimation",
      },
      {
        step: 6,
        label: "Approval",
      },
      {
        step: 7,
        label: "Order No Created",
      },
      {
        step: 8,
        label: "Work in Progress",
      },
      {
        step: 9,
        label: "Final Status",
      },
      {
        step: 10,
        label: "Service Delivery and Payment",
      },
    ];
    state.taskList = taskList;
  },
  SET_Language(state, selectedLanguage) {
    state.selectedLanguage = selectedLanguage;
    localStorage.setItem("selectedLanguage", JSON.stringify(selectedLanguage));
  },
  SET_DEVICE_LIST(state, deviceList) {
    state.deviceList = deviceList;
    localStorage.setItem("deviceList", JSON.stringify(deviceList));
  },
  SET_CATEGORY(state, selectedCategory) {
    state.selectedCategory = selectedCategory;
    state.selectedBrand = null;
    state.selectedModel = null;
    state.defectList = [];
    state.selectedDefects = [];
    state.romList = [];
    state.selectedROM = null;
    state.premiumList = [];
    state.recoveryOptions = [];
    state.selectedRecoveryOption = null;
    state.selectedPremium = null;
  },
  CLEAR_SELECTION(state) {
    state.romList = [];
    state.modelList = [];
    state.selectedBrand = null;
    state.selectedModel = null;
    state.selectedROM = null;
    state.selectedCategory = null;
    state.selectedAddress = null;
    state.selectedDeliverAddress = null;
    state.collectMethod = null;
    state.deliverMethod = null;
    state.collectBranch = null;
    state.deliverBranch = null;
    state.deductionData = null;
    state.selectedGrade = null;
  },
  SET_BRAND(state, selectedBrand) {
    state.selectedBrand = selectedBrand;
    state.defectList = [];
    state.selectedDefects = [];
    state.romList = [];
    state.selectedROM = null;
    state.selectedModel = null;
    state.premiumList = [];
    state.recoveryOptions = [];
    state.selectedRecoveryOption = null;
    state.selectedPremium = null;
  },
  SET_MODEL(state, selectedModel) {
    state.selectedModel = selectedModel;
    state.defectList = [];
    state.selectedDefects = [];
    state.romList = [];
    state.selectedROM = null;
  },
  SET_MODEL2(state, selectedModel2) {
    state.selectedModel2 = selectedModel2;
  },
  // SET_LANGUAGE (state, selectedlanguage) {
  //   state.selectedlanguage = selectedlanguage
  // },
  SET_Year(state, selectedyear) {
    state.selectedyear = selectedyear;
  },
  SET_Display(state, selecteddisplay) {
    state.selecteddisplay = selecteddisplay;
  },
  SET_ROM(state, selectedROM) {
    state.selectedROM = selectedROM;
    state.premiumList = [];
    state.recoveryOptions = [];
    state.selectedRecoveryOption = null;
    state.selectedPremium = null;
  },
  SET_CATEGORY_LIST(state, categoryList) {
    state.categoryList = categoryList;
  },
  SET_BRAND_LIST(state, brandList) {
    state.brandList = brandList;
  },
  SET_MODEL_LIST(state, modelList) {
    state.modelList = modelList;
  },
  SET_MODEL_LIST2(state, modelList2) {
    state.modelList2 = modelList2;
  },
  SET_MODEL_Proc(state, modelList2) {
    state.modelPro = modelList2;
  },
  SET_MODEL_Gen(state, modelList2) {
    state.modelGen = modelList2;
  },
  SET_CUSTOMER(state, modelList2) {
    state.customer = modelList2;
  },
  SET_RECIEPT(state, modelList2) {
    state.reciept = modelList2;
  },
  SET_MODEL_Ram(state, modelList2) {
    state.modelRam = modelList2;
  },
  SET_MODEL_thanku(state, modelList2) {
    state.modelthanku = modelList2;
  },
  SET_ROM_LIST(state, romList) {
    state.romList = romList;
  },
  SET_Extra_LIST(state, romList) {
    state.extra = romList;
  },
  SET_Extra_LIST2(state, romList) {
    state.extra2 = romList;
  },
  SET_DEFECT_LIST(state, defectList) {
    state.defectList = defectList;
  },
  SET_PEMIUM_LIST(state, premiumList) {
    state.premiumList = premiumList;
  },
  SET_PEMIUM(state, premium) {
    state.selectedPremium = premium;
  },
  SET_RECOVERY_OPTIONS(state, recoveryOptions) {
    state.recoveryOptions = recoveryOptions;
  },
  SET_DEDUCTION_DATA(state, deductionData) {
    state.deductionData = deductionData;
  },
  SET_GRADES_DATA(state, gradesData) {
    state.gradesData = gradesData;
  },
  SET_RECOVERY(state, recoveryOption) {
    state.selectedRecoveryOption = recoveryOption;
  },
  SET_GRADE(state, grade) {
    state.selectedGrade = grade;
  },
  ADD_DEFECT(state, item) {
    if (!state.selectedDefects.length) {
      state.taskStage = 3;
    }
    state.selectedDefects.push(item);
  },
  REMOVE_DEFECT(state, item) {
    state.selectedDefects.splice(
      state.selectedDefects.findIndex(
        (v) =>
          v.service_complaint_type_id[0][0] ===
          item.service_complaint_type_id[0][0]
      ),
      1
    );
    if (!state.selectedDefects.length) {
      state.taskStage = 2;
      state.collectMethod = null;
      state.deliverMethod = null;
    }
  },
  REMOVE_FUNCTION(state, item) {
    state.selectedDefects.splice(
      state.selectedDefects.findIndex(
        (v) => v.complaint_id[0][0] === item.complaint_id[0][0]
      ),
      1
    );
    if (!state.selectedDefects.length) {
      state.taskStage = 2;
      state.collectMethod = null;
      state.deliverMethod = null;
    }
  },
  ADD_WARRENTY(state, item) {
    state.selectedWarrenties.push(item);
  },
  REMOVE_WARRENTY(state, item) {
    state.selectedWarrenties.splice(
      state.selectedWarrenties.findIndex((v) => v.id === item.id),
      1
    );
  },
  SET_COLLECT_METHOD(state, collect) {
    state.collectMethod = collect;
  },
  SET_DELIVER_METHOD(state, deliver) {
    state.deliverMethod = deliver;
  },
  UPDATE_STAGE(state, item) {
    state.taskStage = item;
  },
  UPDATE_CHECKOUT(state, item) {
    state.checkoutStage = item;
  },
  SET_ADDRESS(state, address) {
    state.selectedAddress = address;
  },
  SET_DELIVER_ADDRESS(state, address) {
    state.selectedDeliverAddress = address;
  },
  SET_ACTIVE_ADDRESS(state, address) {
    state.activeAddress = address;
  },
  SET_BRANCHS(state, branchList) {
    state.branchList = branchList;
  },
  SET_COLLECT_BRANCH(state, branch) {
    state.collectBranch = branch;
  },
  SET_DELIVER_BRANCH(state, branch) {
    state.deliverBranch = branch;
  },
  SET_SIGN_UP_FORM(state, data) {
    state.signUpForm = data;
  },
  CHECKOUT_SUCCESS(state) {
    state.checkoutStage = "checkout";
    state.activeService = null;
    state.activeServiceType = null;
    state.selectedCategory = null;
    state.selectedBrand = null;
    state.selectedModel = null;
    state.selectedROM = null;
    state.selectedAddress = null;
    state.selectedDefects = [];
    state.selectedRecoveryOption = null;
    state.selectedPremium = null;
    state.categoryList = [];
    state.brandList = [];
    state.modelList = [];
    state.defectList = [];
    state.romList = [];
    state.premiumList = [];
    state.recoveryOptions = [];
  },
  SET_PRODUCT(state, product) {
    state.activeProduct = product;
  },
  SET_REFERAL(state, referal) {
    state.referal = referal;
  },
  ADD_TO_CART(state, product) {
    if (state.activeService && state.activeService.value === "renew") {
      product.lot_id = product.product[0][0];
      product.service = "renew";
    } else {
      product.service = "reuse";
    }
    const obj = state.cartItems.find((p) => p.lot_id === product.lot_id);
    if (obj) {
      obj.quantity = Number(obj.quantity) + 1;
      obj.service = product.service;
    } else {
      product.quantity = 1;
      state.cartItems.push(product);
    }
    localStorage.setItem("cartData", JSON.stringify(state.cartItems));
  },
  UPDATE_CART(state, product) {
    if (
      state.activeServiceType &&
      state.activeServiceType.value === "tempered glass"
    ) {
      product.lot_id = product.service_complaint_type_id[0];
    }
    const obj = state.cartItems.find((p) => p.lot_id === product.lot_id);
    if (obj) {
      obj.quantity = product.quantity;
    }
    localStorage.setItem("cartData", JSON.stringify(state.cartItems));
  },
  REMOVE_FROM_CART(state, item) {
    if (
      state.activeServiceType &&
      state.activeServiceType.value === "tempered glass"
    ) {
      item.lot_id = item.service_complaint_type_id[0];
    }
    state.cartItems.splice(
      state.cartItems.findIndex((v) => v.lot_id === item.lot_id),
      1
    );
    localStorage.setItem("cartData", JSON.stringify(state.cartItems));
  },
  CEAR_CART(state) {
    state.cartItems = [];
    localStorage.setItem("cartData", JSON.stringify(state.cartItems));
  },
  SET_ACTIVE_ORDER(state, order) {
    if (order.state === "quote_reject") {
      state.taskList[7].label = "Quote Rejected";
      state.taskList.splice(8, 3);
      order.payable_amount = 0;
    } else if (order.state === "delivery" && order.repair_rejected) {
      state.taskList[7].label = "Non-Repairable";
      state.taskList.splice(8, 3);
      order.payable_amount = 0;
    }
    state.activeOrder = order;
  },
  SET_IS_LOADING(state, status) {
    state.isLoading = status;
  },
  SET_PRODUCT_LIST(state, productList) {
    state.productList = productList;
    state.productListTimeStamp = Date.now();
    // localStorage.setItem('productList', JSON.stringify(productList))
    localStorage.setItem("productListTimeStamp", state.productListTimeStamp);
  },
  SET_MOBILE_VERIFIED(state, status) {
    state.mobileVerified = status;
    localStorage.setItem("mobileVerified", status);
    if (status === null) {
      localStorage.removeItem("mobileVerified");
    }
  },
  SET_SHIPPING_CHARGE(state, charge) {
    state.shippingCharge = charge;
  },
};
