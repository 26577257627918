<template>
    <div class="container card mt-5 p-3 h-100">
        <div class="d-flex flex-column align-items-center my-5">
            <img class="my-4" src="../assets/scheduleSuccess.png" alt="success" width="150px" />
            <span class="my-4 font-weight-bold fs-4">Your quotation has been successfully scheduled for
                verification.</span>
            <b-button class="my-4 generate-btn px-4" @click="pushToQuote()">Generate a Quote</b-button>
        </div>
    </div>
</template>


<script>
export default {

    methods: {
        pushToQuote: function () {

            return this.$router.push('/b2b')
        }
    }
}

</script>

<style scoped>
.generate-btn {
    background-color: #016938;
    border-radius: .5rem
}
</style>