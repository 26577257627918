<template>
  <b-navbar toggleable type="light" variant="light" class="mt-3">
    <b-navbar-brand href="#">Choose Condition</b-navbar-brand>

    <b-navbar-toggle target="navbar-toggle-collapse4">
      <template #default="{ expanded }">
        <b-icon v-if="expanded" icon="chevron-bar-up">
          <BIconChevronUp />
        </b-icon>
        <b-icon v-else icon="chevron-bar-down">
          <BIconChevronDown />
        </b-icon>
      </template>
    </b-navbar-toggle>

    <b-collapse id="navbar-toggle-collapse4" is-nav>
      <b-row class=" d-flex justify-content-center ">
        <b-col v-for="i in grade_list" :key="i.id">
          <b-row style="flex-wrap: inherit !important;">
            <b-col>
              <div class="info-box3 txt border border-secondary "
                style="max-height: 250px;max-width: 200px;min-height: 250px;min-width: 200px" v-if="i.a"
                @click="checkout_grade('a', i.a, i.a1, i.a_id)">
                <h5 class="mt-3">Condition 1</h5>
                <h6>{{ i.a }} {{ currency }} </h6>
                <ul class="text-left mt-3 ml-4 mr-2" style="padding:0%;font-size: x-small ">
                  <li>
                    No Scratches on the screen
                  </li>
                  <li>
                    Dent-free and minor scratches on body
                  </li>
                  <li>
                    Battery health 90% or Above
                  </li>
                  <li>
                    Fully functional
                  </li>
                  <li>
                    No Device Lock/Sim Lock
                  </li>
                  <li>
                    No missing parts (back cover, sim tray, buttons)
                  </li>
                </ul>
              </div>
            </b-col>
            <b-col>
              <div class="info-box3 txt border border-secondary"
                style="max-height: 250px;max-width: 200px;min-height: 250px;min-width: 200px" v-if="i.b"
                @click="checkout_grade('b', i.b, i.b1, i.b_id)">
                <h5 class="mt-3">Condition 2</h5>
                <h6>{{ i.b }} {{ currency }}</h6>
                <ul class="text-left mt-3 mr-2  ml-4" style="padding:0% ;font-size: x-small">
                  <li>
                    Visible scratches on the screen, open or swapped
                  </li>
                  <li>
                    Major scratches / dents on back cover and housing.
                  </li>
                  <li>
                    Battery health 80% or Above
                  </li>
                  <li>
                    Issues with either mic, speaker or buttons
                  </li>
                  <li>
                    No Device Lock/Sim Lock
                  </li>
                  <li>
                    No missing parts (back cover, sim tray, buttons)
                  </li>
                </ul>
              </div>
            </b-col>
            <b-col>
              <div class="info-box3 txt border border-secondary"
                style="max-height: 250px;max-width: 200px;min-height: 250px;min-width: 200px" v-if="i.c"
                @click="checkout_grade('c', i.c, i.c1, i.c_id)">
                <h5 class="mt-3">Condition 3</h5>
                <h6>{{ i.c }} {{ currency }} </h6>
                <ul class="text-left mt-2 ml-4 mr-2" style="padding:0% ;font-size: x-small">
                  <li>
                    LCD dots/ lines/ discoloration/Screen Burn, Screen Touch not working
                  </li>
                  <li>
                    Cracked device
                  </li>
                  <li>
                    Battery health: Service indicator on
                  </li>
                  <li>
                    Any of the following not working: Touch ID, Face ID, Camera
                  </li>
                  <li>
                    Device Lock/Sim Lock
                  </li>
                  <li>
                    Missing parts (back cover, sim tray or buttons)
                  </li>
                </ul>
              </div>
            </b-col>
          </b-row>
          <hr />
          <h6 class="text-center pt-2"><i>
              Please ensure that grading conforms to the definition, otherwise, it will result in a
              price variance of the product.
            </i>
          </h6>
        </b-col>
      </b-row>
    </b-collapse>
  </b-navbar>

</template>

<script>
import api from '../../api'
import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'

export default {
  name: 'CategorySelect',
  props: {
    model_id: {}

  },
  data() {
    return {
      grade_list: null,
      grade_selected: null,
      currency: null

    }
  },
  components: {
    BIconChevronUp,
    BIconChevronDown
  },
  computed: {
    categoryList: function () {
      return this.$store.state.categoryList
    },
  },
  watch: {
    model_id: {
      handler(newValue) {
        this.getPrice();
      },
      immediate: true // Run this watcher immediately upon component creation
    }
  },
  methods: {
    checkout_grade: function (grade, price, vendor, id) {
      this.$emit('updatecondition', { id: id, grade: grade, price: price, vendor: vendor });
      this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse4')
      this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse5')

    },
    getPrice: function () {

      console.log('checking')
      let currentDate = new Date().toJSON().slice(0, 10);

      const inputs = {
        'id': this.model_id,
        'user': this.$store.state.user.uid,
        'current_date': currentDate,
        'country': 'United Arab Emirates',
      }
      api
        .request(process.env.VUE_APP_Get_Price_Data, inputs)
        .then((response) => {
          var data = response.data
          if (data.errorcode) {
            return
          }
          if (data.result) {
            // console.log('sdsds')
            this.grade_list = data.result
            // this.$store.commit('SET_GRADES_DATA', data.result)
            this.currency = data.result[0].currency

            // this.repair = false
            // this.country_code = data.result[0].country_code
            // this.grade_selected = true

          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
  }
};
</script>

<style scoped>
.info-box3 {
  border-color: green !important;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.info-box3:hover {
  background-color: green;
  color: white;
}
</style>