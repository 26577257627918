<template>
  <div class="quote-summary">
    <h5 class="text-left mb-3">Quote Summary</h5>
    <table>
      <thead>
        <tr>
          <th>Device</th>
          <th>Condition</th>
          <th>Non-Validated Quantity</th>
          <th>Validated Quantity</th>
          <th>Estimation</th>
          <th>Device IMEI/Serial no.</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="device, index in  (isFileUpload === '1' ? fileDevList : dynamicData)" :key="device.id">
          <td>{{ device.brand_name }} {{ device.model_name }}</td>

          <td v-if="device.grade">
            <b v-if="device.grade === 'Condition 1' || device.grade === 'a'">Condition 1</b>
            <b v-if="device.grade === 'Condition 2' || device.grade === 'b'">Condition 2</b>
            <b v-if="device.grade === 'Condition 3' || device.grade === 'c'">Condition 3</b>
          </td>
          <td class="text-danger"> {{ countNonValidatedIMEIs(device.imei) }}</td>
          <td>{{ device.quantity - countNonValidatedIMEIs(device.imei) }}</td>

          <td>
            AED {{ device.quantity * device.price }}
          </td>

          <td>
            <div class="d-flex flex-column">
              <div>
                <b-button v-if="isFileUpload === '0'" class="tab-btn mb-2" @click="triggerFileInput2()">Upload IMEI's
                  no.</b-button>
                <input ref="fileInput" type="file" style="display: none;" @change="handleFileChange2" />
              </div>
              <b-button class="tab-btn-view"
                @click="isFileUpload === '1' ? viewIMEI(device) : viewEmptyImeiModal(device, index)">View
                IMEI's/Serial no.</b-button>
            </div>
          </td>

          <td class="text-danger" v-if="countNonValidatedIMEIs(device.imei) > 0">
            {{ countNonValidatedIMEIs(device.imei) }} IMEI's are not validated
          </td>
          <td class="text-success" v-else>
            All {{ device.quantity }} devices are validated
          </td>
        </tr>
      </tbody>
    </table>
    <EstimationDisplay :estimation="totalEstimation" />
    <b-modal v-model="showModal" title="Device IMEI / Serial Number" size="lg">
      <div class="imei-list">
        <h5>{{ deviceName }} - Device IMEI / Serial Number</h5>

        <!-- Display each IMEI number -->
        <div v-for="(imei, index) in imeiList" :key="index" class="imei-item d-flex align-items-center">
          <span class="mr-3">{{ index + 1 }}.</span>
          <input type="text" v-model="imei.value"
            :class="['form-control', 'imei-input', { 'is-valid': imei.validation, 'is-invalid': !imei.validation }]"
            disabled />
          <div class="d-flex align-items-center i-box"
            @click="isFileUpload === '1' ? removeIMEI(selectedDeviceId, index) : removeDynamicImei(index)">
            <img src="../../assets/trash2.png" alt="trash" width="16px">
          </div>
        </div>

        <!-- Add new IMEI input -->
        <div class="add-imei">
          <b-button @click="addIMEI()" class="model-btn">+ Add New IMEI</b-button>
        </div>

        <!-- Validate Button -->
        <div v-if="showimeiInput" class="d-flex justify-content-center align-items-center mt-3">
          <input type="text" v-model="newIMEI" placeholder="Add New IMEI" class="form-control"
            @input="validateIMEIInput" />
          <b-button class="mx-3 model-btn" variant="primary" @click="addEntry">Validate</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import QuantitySelector from './QuantitySelector.vue';
import EstimationDisplay from './EstimationDisplay.vue';
// import trash from '../../assets/trash.png';

export default {
  components: { QuantitySelector, EstimationDisplay },
  data() {
    return {
      quantity: 15,
      estimation: 22500,
      device_list: [],
      showModal: false,
      deviceName: "iPhone 14 64 GB",
      showimeiInput: false,
      imeiList: [],
      fromFileImport: false,
      newIMEI: "",
      fileDevList: [
        { "id": 444580, "category_name": "Mobile", "brand_name": "Apple", "model_name": "Apple iPhone 14 Pro Max 256 GB", "grade": "a", "price": 1720, "quantity": 4, "imei": [{ value: "356616946299420", validation: true }, { value: "353505805516763", validation: false }, { value: "359370791555133", validation: true }, { value: "356103505502965", validation: true }] },

        { "id": 444566, "category_name": "Mobile", "brand_name": "Apple", "model_name": "Apple iPhone 13 Pro Max 256 GB", "grade": "b", "price": 1344, "quantity": 3, "imei": [{ value: "351091211741396", validation: true }, { value: "359370790899128", validation: true }, { value: "356303489009629", validation: true }] },

        { "id": 444594, "category_name": "Mobile", "brand_name": "Apple", "model_name": "Apple iPhone 15 Pro Max 512 GB", "grade": "c", "price": 616, "quantity": 5, "imei": [{ value: "356737681523277", validation: true }, { value: "355319546187621", validation: true }, { value: "356616948373298", validation: true }, { value: "350069701569304", validation: false }, { value: "351253396976425", validation: false }] }
      ],
      dynamicData: [],
    };
  },
  computed: {
    deviceList: function () {
      return this.$store.state.deviceList
    },
    isFileUpload: function () {
      return localStorage.getItem('isFile')
    },
    totalEstimation() {
      const list = this.isFileUpload === '1' ? this.fileDevList : this.deviceList;
      return list.reduce((total, device) => {
        return total + device.quantity * device.price;
      }, 0);
    },
  },
  methods: {
    viewIMEI(device) {

      this.imeiList = device.imei.map(imei => ({
        value: imei.value,
        validation: imei.validation ?? false
      }));
      this.deviceName = `${device.brand_name} ${device.model_name} ${this.checkTitleCondition(device.grade)}`;
      this.showModal = true;
      this.selectedDeviceId = device.id;
    },
    checkTitleCondition(text) {
      if (text === 'a') return 'Condition 1';
      else if (text === 'b') return 'Condition 2';
      else if (text === 'c') return 'Condition 3';

    },
    countNonValidatedIMEIs(imeiArray) {
      return imeiArray ? imeiArray.filter(imei => !imei.validation).length : 0;
    },
    viewEmptyImeiModal(device, index) {
      this.deviceName = `${device.brand_name} ${device.model_name} ${this.checkTitleCondition(device.grade)}`;
      this.showModal = true;
      console.log('index', index)
      this.selectedDeviceId = index;
      this.imeiList = Array.from({ length: device.quantity }, () => ({
        value: this.generateRandomIMEI(),
        validation: true
      }));
    },
    generateRandomIMEI() {
      return Array.from({ length: 14 }, () => Math.floor(Math.random() * 10)).join('');
    },
    validateIMEIInput() {

      this.newIMEI = this.newIMEI.replace(/\D/g, '').slice(0, 14);
    },

    triggerFileInput2() {
      // Check if the ref is available and a valid input element
      if (this.$refs.imeiInput) {
        this.$refs.imeiInput.click();
      } else {
        console.error('imeiInput ref is not available or not a file input element');
      }
    },
    handleFileChange2(event) {
      const file = event.target.files[0];
      // Handle the file upload here
      console.log(file);
    },

    validateIMEI(index) {

      const imei = this.imeiList[index];
      imei.valid = Math.random() > 0.5;
      imei.invalid = !imei.valid;
    },
    addIMEI() {
      this.showimeiInput = true
    },
    removeIMEI(deviceId, imeiIndex) {
      const device = this.isFileUpload === '1'
        ? this.fileDevList.find(dev => dev.id === deviceId)
        : this.deviceList.find(dev => dev.id === deviceId);

      if (device) {
        device.imei.splice(imeiIndex, 1);

        this.imeiList = device.imei.map(imei => ({
          value: imei.value,
          validation: imei.validation ?? false
        }));

        device.quantity = device.imei.length;

        localStorage.setItem('fileImportList', JSON.stringify(this.fileDevList));

        this.totalEstimation();
      }
    },
    removeDynamicImei(index) {

      this.imeiList.splice(index, 1);
      this.dynamicData[this.selectedDeviceId].quantity -= 1;
      localStorage.setItem("deviceList", JSON.stringify(this.dynamicData));
    },

    validateAllIMEIs() {
      this.imeiList.forEach((imei, index) => this.validateIMEI(index));
    },


    addEntry() {
      if (this.newIMEI.length === 14) {
        // Directly push the new IMEI to the bound imeiList
        this.imeiList.push({ value: this.newIMEI, validation: false });

        // Also, update the device quantity based on the new IMEI count
        const deviceList = this.fileDevList;
        const device = deviceList.find(d => d.id === this.selectedDeviceId);
        if (device) {
          device.quantity = device.imei.length; // Sync quantity with imei count
        }

        // Clear the new IMEI input
        this.newIMEI = "";
      } else {
        alert("IMEI must be exactly 14 digits.");
      }
    }
  },
  mounted() {

    this.dynamicData = this.deviceList;
    localStorage.setItem('fileImportList', JSON.stringify(this.fileDevList));

  }
};
</script>

<style scoped>
.quote-summary {
  width: 100%;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #2c3e50;
  font-weight: bold;
  text-align: left;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 1);
  text-align: center !important;

}

th {
  background-color: #006400;
  color: #fff;
  text-align: center !important;

}

.edit-options {
  gap: 10px;
}

.edit {
  background-color: #fdd835;
  color: #2c3e50;
}

.remove {
  background-color: #d32f2f;
}

.tab-btn {
  background-color: transparent;
  border: 1px solid #AEB2BF80;
  color: rgba(1, 105, 56, 1);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;

}

.tab-btn-view {
  background-color: rgba(1, 105, 56, 1);
  border: 1px solid #AEB2BF80;
  color: #fff;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;

}

.imei-list {
  max-height: 500px;
  overflow-y: auto;
}

.imei-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.imei-input {
  flex: 1;
  margin-right: 0.5rem;
}

.add-imei {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1rem;
}

.model-btn {
  background-color: rgba(1, 105, 56, 1);
  border: none;
  border-radius: 1rem;
}

.i-box {
  padding: .5rem;
  background-color: #f6f7f5;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.i-box:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
