<template>
  <div class="estimation-display">
    <h2 style="color: #006400;font-weight: 800;">Upto AED {{ estimation }}</h2>
    <h6 v-if="expiry" style="color: #006400;font-weight: 800;">Expiry Date <span class="text-secondary">{{ expiry
        }}</span></h6>
    <h6 v-if="validity" style="color: #006400;font-weight: 800;">Total Valid Days <span class="text-secondary">{{
      validity
    }}</span></h6>
    <p class="details">(Price Inclusive of VAT / Taxes where applicable)</p>
    <p class="terms">All values are subject to device inspection upon receipt...</p>

  </div>
</template>

<script>
export default {
  props: ['estimation', 'expiry', 'validity'],
};
</script>

<style scoped>
.estimation-display {
  text-align: center;
  margin-top: 20px;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
}

h3 {
  color: #2c3e50;
  font-size: 24px;
  font-weight: bold;
}

.details,
.terms {
  color: #777;
  font-size: 12px;
  margin-top: 5px;
}
</style>
